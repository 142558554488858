import React from 'react'
import './attachment.scss'
import confirm from '../../../../../assets/icons/confirm.svg'

interface submissionInterface {
  tracking_id: string
}
const SubmissionSucces = ({ tracking_id }: submissionInterface) => {
  console.log('id', tracking_id)
  return (
    <div className='submission-succes'>
      <div className='submission-icon'>
        <img src={confirm} alt='confirm' />
      </div>
      <h2>Thank you for your submission!</h2>
      <p>A BGIS Property Service Coordinator will contact you shortly.</p>
      <p id='task_id'>
        To view progress refer to Task ID <span>{tracking_id}</span>
      </p>
    </div>
  )
}
export default SubmissionSucces
