/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import axios from 'axios'
import './FileAttachment.module.scss'
import { Spinner, Button } from 'reactstrap'
import FileItem from './FileItem'
interface IFileAttachment {
  disabled?: boolean
  onChange?: (files: any) => void
  atachements: any[]
}

const FileAttachment = ({
  disabled = false,
  onChange,
  atachements
}: IFileAttachment) => {
  const authToken = localStorage.getItem('api_token')
  const user_DataString = localStorage.getItem('dwportal_user')
  const user_Data = JSON.parse(user_DataString ?? '')
  const userName = user_Data.first_name + ' ' + user_Data.last_name
  const [files, setFiles] = useState<any[]>([])
  const [uploading, setUploading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  useEffect(() => {
    onChange && onChange(files)
  }, [files])
  useEffect(() => {
    setFiles(atachements)
  }, [atachements])
  const handleFileChange = useCallback(async (fileList: File[]) => {
    const fileNames: string[] = []
    setUploading(true)
    setLoading(true)
    const formData = new FormData()
    fileList.forEach((file) => {
      formData.append('files[]', file)
      fileNames.push(file.name)
    })
    const config = {
      method: 'post',
      url: `https://io.dw.api.protovate.com/api/upload/attach`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authToken}`
      },
      data: formData
    }
    axios(config)
      .then((response: any) => {
        const data = response.data.data.files
        data.forEach((element: any, index: number) => {
          setFiles((prevstate: any) => [
            ...prevstate,
            {
              fileName: fileNames[index],
              lastUpdated: response.data.data.created_at,
              name: userName,
              url: element.url,
              extension: element.extension
            }
          ])
        })
      })
      .catch((error) => {
        // Handle errors
        console.error(error)
      })
    setUploading(false)
    setLoading(false)

    // getFiles()
  }, [])

  const removeFile = (index: number) => {
    const newArray = files.slice(0, index).concat(files.slice(index + 1))
    setFiles(newArray)
  }
  return (
    <div>
      <div className='file-list'>
        {loading && (
          <div className='flex-center'>
            <Spinner className='spinner' />{' '}
          </div>
        )}
        {files.map((item, index) => (
          <FileItem
            file={item}
            key={index}
            removeFile={() => removeFile(index)}
          />
        ))}
      </div>
      <Dropzone onDrop={handleFileChange} disabled={disabled}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              {...getRootProps()}
              className={`drop-zone ${disabled ? 'disabled' : ''}`}
            >
              <input {...getInputProps()} />
              <div className='file-container'>
                <span className='icon'>
                  <i className='fa-regular fa-file'></i>
                </span>
                {uploading ? (
                  <Spinner className='spinner' />
                ) : (
                  <div className='text-center'>
                    <p className='description'>
                      Drag and drop a file here to attach it
                    </p>
                    <p className='browse-content'>
                      Click <span> here</span> to browse your system
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div {...getRootProps()}>
              <Button
                color='secondary'
                className='btn'
                style={{ marginTop: '20px' }}
              >
                Attach
              </Button>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  )
}
export default FileAttachment
