import React, { useEffect, useMemo, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { AdminPortalLayout } from '@digitalworkflow/dwreactcommon'
import { AuthService } from '@digitalworkflow/dwloginclient'

import '@digitalworkflow/dwreactcommon/dist/index.css'
import './assets/scss/index.scss'
import SetToken from './pages/SetToken'
// import BuildingSearch from './pages/BuildingSearch'
import ComingSoon from './pages/ComingSoon'
import PageLoader from './pages/PageLoader'
import DigitalForms from './pages/DigitalForms'

import { useAuth } from './context/AuthContext'

import logoImage from './assets/images/bgis-logo.png'
import logoSmImage from './assets/images/bgis-logo-sm.png'

import IdleWatch from './components/IdleWatch'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { menuItems, profileMenuItems, loginPortalId } from './constants'
import { Portal } from './types/Portal'
import ProjectServicesInitiationForm from './pages/ProjectServicesInitiationForm'

AuthService.setEnvironment('dev')

const GuestRouteRedirect = () => {
  // Capture the internal URL
  const internalUrl = window.location.pathname

  // Store the internal URL in local storage
  localStorage.setItem('dwportal_redirect_url', internalUrl)

  window.location.href = `https://logindev.dw.protovate.com/login/${loginPortalId}`

  return null
}

const App = () => {
  const { isAuthenticated, user } = useAuth()
  const [portal, setPortal] = useState<Portal>()

  useEffect(() => {
    if (user.portals_enabled && user.portals_enabled?.length > 0) {
      getPortalDetails(loginPortalId)
    }
  }, [user.portals_enabled])

  const getPortalDetails = (id: string) => {
    try {
      const portalInfo = user.portals_enabled?.find(
        (item) => item.login_portal_id === id
      )
      if (portalInfo) {
        const portalData: Portal = {
          buttons: portalInfo.buttons,
          login_portal_id: portalInfo.login_portal_id,
          portal_logo: portalInfo.portal_logo,
          portal_name: portalInfo.portal_name,
          redirect_location_after_success:
            portalInfo.redirect_location_after_success,
          portal_theme: portalInfo.portal_theme,
          portal_logo_full: ''
        }
        localStorage.setItem('dwtheme', portalData?.portal_theme ?? '')
        handleTheme()
        setPortal(portalData)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleTheme = () => {
    const theme = localStorage.getItem('dwtheme')
    document.body.classList.add('theme-' + theme ?? 'bgis')
  }

  const logo = useMemo((): string => {
    let logoIcon = logoImage
    if (portal?.portal_logo) {
      logoIcon = `https://logindev.dw.protovate.com/api/v1/portals/logo/${portal.portal_logo}`
    }
    return logoIcon
  }, [portal])

  return (
    <BrowserRouter>
      <IdleWatch />
      <Routes>
        <Route path='/' element={<SetToken />} />
        <Route
          path='/'
          element={
            isAuthenticated
              ? React.createElement(AdminPortalLayout, {
                  menuItems,
                  profileMenuItems,
                  logoImage: logo,
                  logoSmImage,
                  username: user?.email
                })
              : React.createElement(GuestRouteRedirect, {})
          }
        >
          {/* <Route path='building-search' element={<BuildingSearch />} /> */}
          <Route path='dashboard' element={<ComingSoon />} />
          <Route path='task-inbox' element={<ComingSoon />} />
          <Route
            path='/page/digital-forms'
            element={<ProjectServicesInitiationForm />}
          />
          <Route path='/page/dashboard' element={<DigitalForms />} />
          <Route path='*' element={<PageLoader />} />
        </Route>
      </Routes>
      <ToastContainer
        position='bottom-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
    </BrowserRouter>
  )
}

export default App
