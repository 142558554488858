import React from 'react'
import './welcome.scss'
import pdf from '../../../../../assets/images/pdf-icon-img.png'
import downloadBtn from '../../../../../assets/images/close-download.svg'
import downloadPdf from '../../../../../assets/uploads/PSIF_HELP_GUIDE_2023.pdf'
import { Link } from 'react-router-dom'

// import { Label, Input, FormGroup } from 'reactstrap'

const descArray = [
  'This form contains 4 sections. You can complete each section by selecting the section tab just above this white box.',
  'Within each section, fill out the requested information as best as you can.',
  'You may return to this request at any time and continue to add details. You may also add notes to each section by using the "Task Notepad.',
  'When you have entered all of the information, press the “Submit Request” button on the bottom of any page.'
]
const index = () => {
  // const [isChecked, setChecked] = useState(false)
  //
  // // Function to handle checkbox change
  // const handleCheckboxChange = () => {
  //   setChecked(!isChecked)
  // }
  //
  // const handleClick = () => {
  //   // Implement your logic when the button is clicked
  //   console.log('Start Request button clicked!')
  // }

  return (
    <>
      <div className='digital-form-welcome'>
        <h2>Welcome to the Project Services Initiation Form (PSIF)</h2>
        <div className='welcome-content'>
          <div className='instructions'>
            {descArray?.map((p, i) => (
              <p key={i}>{p}</p>
            ))}
          </div>

          <div className='gap-div'></div>

          <div className='download-file'>
            <span>Please download the PDF User Manual</span>
            <div className='download-manual'>
              <div className='pdf-file'>
                <img src={pdf} alt='logo' />
                <div className='file-name-size'>
                  <span className='file-name'>PSIF_HELP_GUIDE_2023.pdf</span>
                  <span className='file-size'>1Mb</span>
                </div>
              </div>
              <div>
                <Link
                  to={downloadPdf}
                  target='_blank'
                  download='PSIF_HELP_GUIDE_2023.pdf'
                  rel='noopener noreferrer'
                >
                  <img src={downloadBtn} alt='download button' />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default index
