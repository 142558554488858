import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import './ContactInformation.scss'

import { Input } from 'reactstrap'
interface FormProps {
  onSubmit: (data: FormData) => void
  initialValues?: FormData | undefined
  isNewContactFormVisible: boolean
  isEditing: boolean
  selectedContactIndex: number | null
  contacts: any[]
}

interface FormData {
  contactName: string | undefined
  radioOption: string | undefined
  dropdown: string | undefined
  clientEmail: string | undefined
  phoneNumber: string | undefined
  role: string | undefined
}

const Form: React.FC<FormProps> = ({
  onSubmit,
  isNewContactFormVisible,
  selectedContactIndex,
  isEditing,
  contacts,
  initialValues = {
    contactName: '',
    radioOption: '',
    dropdown: '',
    clientEmail: '',
    phoneNumber: '',
    role: ''
  }
}) => {
  const reqApiToken = localStorage.getItem('api_token')
  const user_DataString = localStorage.getItem('dwportal_user')
  const [clientGroupName, setClientGroupName] = useState<any>()
  const [selectClassName, setSelectClassName] = useState(
    'select-no-data-element'
  )
  const validationSchema = Yup.object({
    contactName: Yup.string()
      .matches(
        /^[a-zA-Z]+(?: [a-zA-Z]+)*$/,
        '*Contact Name should contain only alphabetic characters'
      )
      .required('*Contact Name is required'),
    radioOption: Yup.string().when((_, schema) => {
      const shouldValidate =
        (isNewContactFormVisible &&
          selectedContactIndex === null &&
          contacts.length === 0) ||
        (isEditing && selectedContactIndex === 0)

      return shouldValidate
        ? schema.required('*Client Group Name is required')
        : schema
    }),
    dropdown: Yup.string().required('*Please Select an option'),
    role: Yup.string().when((_, schema) => {
      const shouldValidate =
        (isNewContactFormVisible &&
          selectedContactIndex === null &&
          contacts.length === 0) ||
        (isEditing && selectedContactIndex === 0)

      return shouldValidate
        ? schema
        : schema
            .matches(
              /^\S.*\S$/,
              '*Role Name should not start or end with a space'
            )
            .matches(
              /^[a-zA-Z\s]+$/,
              '*Role Name should only contain letters and spaces'
            )
            .required('*Role Name is required')
    }),
    clientEmail: Yup.string()
      .email('Invalid email address')
      .required('*Email is required'),
    phoneNumber: Yup.string()
      .matches(/^(\d+(\s?-?\d+)?)+$/, '*Phone number must contain only numbers')
      .required('*Phone number is required')
  })
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values)
      console.log('values', values)
    }
  })
  useEffect(() => {
    if (initialValues.contactName !== '') {
      for (const key in initialValues) {
        if (
          Object.prototype.hasOwnProperty.call(initialValues, key) &&
          initialValues[key] === null
        ) {
          initialValues[key] = ''
        }
      }
    }
  }, [])

  const ClientGroupName = async () => {
    try {
      const res = await axios.get(
        'https://io.dw.api.protovate.com/api/client-groups',
        {
          headers: {
            Authorization: `Bearer ${reqApiToken}`,
            Accept: 'application/json'
          }
        }
      )

      if (res.status === 200) {
        const client = res.data.data.find(
          (client: any) => client.code === formik.values.radioOption
        )
        setClientGroupName(res.data.data)
        if (client.sub_groups) {
          setSelectClassName('select-data-element')
        } else {
          setSelectClassName('select-data-element')
        }
      }
    } catch (error) {
      console.error('Error fetching client groups:', error)
    }
  }
  useEffect(() => {
    if (user_DataString) {
      const user_Data = JSON.parse(user_DataString)
      if (initialValues.contactName === '') {
        initialValues.contactName =
          user_Data.first_name + ' ' + user_Data.last_name
        initialValues.clientEmail = user_Data.email
        initialValues.phoneNumber = user_Data.phone
      }
    }
  }, [user_DataString])
  useEffect(() => {
    ClientGroupName()
  }, [formik.values.radioOption])

  const handleRadioClick = (index: number) => {
    if (index === 0) {
      setSelectClassName('select-no-data-element')
    } else if (index === 1) {
      setSelectClassName('select-data-element')
    }
  }

  const handleDropdownChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('dropdown', e.target.value)
  }
  return (
    <div className='digital-form-contact-information-form'>
      {(isNewContactFormVisible &&
        selectedContactIndex === null &&
        contacts.length === 0) ||
      (isEditing && selectedContactIndex === 0) ? (
        <>
          <h2>Client Contact </h2>
          <p>
            To continue the form, at least one person must be listed as the
            “Client Contact”. Additional contacts may be added after this form
            has been completed.
          </p>
        </>
      ) : (
        <>
          <h2>Add Additional Contact</h2>
          <p>
            You have added 1 contact to this request. Continue to add additional
            contacts as needed for this request.
          </p>
        </>
      )}
      <form onSubmit={formik.handleSubmit}>
        <div>
          {(isNewContactFormVisible &&
            selectedContactIndex === null &&
            contacts.length === 0) ||
          (isEditing && selectedContactIndex === 0) ? (
            <label>Client Contact Name</label>
          ) : (
            <label>Additional Contact Name</label>
          )}
          <input
            type='text'
            id='contactName'
            name='contactName'
            value={formik.values.contactName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.contactName && formik.errors.contactName ? (
            <span className='errorText'>{formik.errors.contactName}</span>
          ) : null}
        </div>
        {(isNewContactFormVisible &&
          selectedContactIndex === null &&
          contacts.length === 0) ||
        (isEditing && selectedContactIndex === 0) ? (
          <>
            <div className='radio-button'>
              <label>Client Group Name</label>

              {clientGroupName &&
                clientGroupName.map((client: any, index: number) => (
                  <div key={index}>
                    <input
                      type='radio'
                      name='radioOption'
                      value={client.code}
                      checked={formik.values.radioOption === client.code}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      defaultChecked={index === 0}
                      onClick={() => {
                        formik.setFieldValue('radioOption', client.code)
                        handleRadioClick(index)
                      }}
                    />
                    <label>{client.text_en}</label>
                  </div>
                ))}
              {formik.touched.radioOption && formik.errors.radioOption ? (
                <span className='errorText'>{formik.errors.radioOption}</span>
              ) : null}
            </div>
            {formik.values.radioOption === 'ORM' && (
              <div>
                <Input
                  id='dropdown'
                  name='dropdown'
                  type='select'
                  value={formik.values.dropdown}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleDropdownChange(e)
                  }
                  className={selectClassName && selectClassName}
                  onBlur={formik.handleBlur}
                >
                  <option value=''>Select an Option</option>
                  {clientGroupName &&
                    clientGroupName
                      .filter(
                        (client: any) =>
                          formik.values.radioOption === client.code
                      )
                      .map((client: any) =>
                        client.sub_groups.map((subGroup: any, key: number) => (
                          <option key={key} value={subGroup.text_en}>
                            {subGroup.text_en}
                          </option>
                        ))
                      )}
                </Input>
                {formik.touched.dropdown && formik.errors.dropdown ? (
                  <span className='errorText'>{formik.errors.dropdown}</span>
                ) : null}
              </div>
            )}
          </>
        ) : (
          <div>
            <label>Role for this Request</label>
            <input
              type='text'
              id='role'
              name='role'
              value={formik.values.role}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.role && formik.errors.role ? (
              <span className='errorText'>{formik.errors.role}</span>
            ) : null}
          </div>
        )}

        <div>
          {(isNewContactFormVisible &&
            selectedContactIndex === null &&
            contacts.length === 0) ||
          (isEditing && selectedContactIndex === 0) ? (
            <label htmlFor='clientEmail'>Client Contact Email Address</label>
          ) : (
            <label htmlFor='clientEmail'>
              Additional Contact Email Address
            </label>
          )}
          <input
            type='text'
            id='clientEmail'
            name='clientEmail'
            value={formik.values.clientEmail}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.clientEmail && formik.errors.clientEmail ? (
            <span className='errorText'>{formik.errors.clientEmail}</span>
          ) : null}
        </div>
        <div>
          {(isNewContactFormVisible &&
            selectedContactIndex === null &&
            contacts.length === 0) ||
          (isEditing && selectedContactIndex === 0) ? (
            <label htmlFor='phoneNumber'>Client Contact Phone Number</label>
          ) : (
            <label htmlFor='phoneNumber'>Additional Contact Phone Number</label>
          )}
          <input
            type='text'
            id='phoneNumber'
            name='phoneNumber'
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
            <span className='errorText'>{formik.errors.phoneNumber}</span>
          ) : null}
        </div>
        <button type='submit'>Save</button>
      </form>
    </div>
  )
}

export default Form
