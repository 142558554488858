import React, { useEffect, useState } from 'react'
import FileAttachment from '../../../../../components/FileAttachment'
import CheckList from './Checklist'
import SubmissionSucces from './SubmissionSucces'
import './attachment.scss'
import axios from 'axios'

const Attachments = ({ setFilesData, activeComponent, tracking_id }: any) => {
  const [atachments, setAtachments] = useState<any[]>([])
  const startRequestId = localStorage.getItem('CurrentRequestId')
  const authToken = localStorage.getItem('api_token')
  const getSavedAttachementsInfo = async () => {
    const res = await axios.get(
      `https://io.dw.api.protovate.com/api/requests/${startRequestId}/sections/5/view`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      }
    )
    if (res.status === 200) {
      if (res.data.data.data != null) {
        setAtachments(res.data.data.data)
      }
    }
  }
  useEffect(() => {
    getSavedAttachementsInfo()
  }, [])
  const handleChange = (files: File[]) => {
    setFilesData(files)
  }
  return (
    <div className='inner-file-container'>
      {activeComponent === 'FileAttachment' && (
        <FileAttachment onChange={handleChange} atachements={atachments} />
      )}
      {activeComponent === 'checkList' && <CheckList />}
      {activeComponent === 'SubmissionSucces' && (
        <SubmissionSucces tracking_id={tracking_id} />
      )}
    </div>
  )
}

export default Attachments
