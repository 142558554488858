import React, { useMemo } from 'react'
import { ReactComponent as ImageIcon } from '../../assets/images/icons/image.svg'
import { ReactComponent as VideoIcon } from '../../assets/images/icons/video.svg'
import { ReactComponent as WordIcon } from '../../assets/images/icons/word.svg'

const defaultImage = {
  type: 'default',
  containerClass: 'default',
  Icon: <span className='fa-regular fa-file' />
}

const fileTypes = [
  {
    type: 'jpg',
    containerClass: 'image',
    Icon: <ImageIcon />
  },
  {
    type: 'video',
    containerClass: 'video',
    Icon: <VideoIcon />
  },
  {
    type: 'doc',
    containerClass: 'word',
    Icon: <WordIcon />
  },
  {
    type: 'csv',
    containerClass: 'csv',
    Icon: <span className='fa-solid fa-file-csv' />
  },
  {
    type: 'ppt',
    containerClass: 'ppt',
    Icon: <span className='fa-solid fa-file-ppt' />
  },
  {
    type: 'xls',
    containerClass: 'xls',
    Icon: <span className='fa-solid fa-file-xls' />
  },
  {
    type: 'pdf',
    containerClass: 'pdf',
    Icon: <span className='fa-solid fa-file-pdf' />
  }
]

interface IFileIcon {
  type: string
}
const FileIcon = ({ type }: IFileIcon) => {
  const icon = useMemo(() => {
    return fileTypes.find((item) => type.includes(item.type)) ?? defaultImage
  }, [type])

  return <div className={`fileType ${icon.containerClass}`}>{icon.Icon}</div>
}
export default FileIcon
