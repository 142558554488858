import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthService, AuthResultType } from '@digitalworkflow/dwloginclient'
import { useAuth } from '../../context/AuthContext'
import { loginPortalId } from '../../constants'

const authService = AuthService.instance()

const SetToken = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { setIsAuthenticated, setUser } = useAuth()

  useEffect(() => {
    const init = async () => {
      const params = new URLSearchParams(location.search)
      const token = params.get('token')
      console.log(token)
      if (token) {
        localStorage.setItem('dwportal_auth_token', token)
      }
      const authToken = localStorage.getItem('dwportal_auth_token') ?? ''
      AuthService.setPortalLoginId(loginPortalId)
      const res: AuthResultType = await authService.authUserFromToken(authToken)
      console.log(res)
      if (res) {
        if (res?.is_error === false) {
          setIsAuthenticated(true)
          setUser({
            portals_enabled: res.data.user.portals_enabled,
            username: res.data.user.email,
            email: res.data.user.email
          })
          localStorage.setItem('dwportal_user', JSON.stringify(res.data.user))

          // Retrieve the redirect URL from local storage
          // const redirectUrl = localStorage.getItem('dwportal_redirect_url')
          // if (redirectUrl) {
          //   return navigate(redirectUrl)
          // } else {
          return navigate('/page/dashboard')
          // }
        }
      }
      setIsAuthenticated(false)
      localStorage.removeItem('dwportal_auth_token')
      localStorage.removeItem('dwportal_user')
      window.location.href = `https://logindev.dw.protovate.com/login/${loginPortalId}`
    }
    init()
  }, [])

  return <div className='token-page'></div>
}

export default SetToken
