import { MenuItem, ProfileMenuItem } from '@digitalworkflow/dwreactcommon'
import { AuthService } from '@digitalworkflow/dwloginclient'
import Logout from '../components/Logout'

const authService = AuthService.instance()

export const loginPortalId = window.location.hostname?.startsWith('io')
  ? 'TiJklMkB'
  : '3PvIsf5l'
export const projectName = 'dwportal'

export const menuItems: Array<MenuItem> = [
  {
    item_type: 'Label',
    title_en: 'MY WORKFLOW',
    view_groups: null
  },
  {
    item_type: 'Link',
    title_en: 'Dashboard',
    icon: 'fa-light fa-table-columns',
    view_groups: 'PI, PSI',
    route: '/dashboard',
    children: [
      {
        item_type: 'Link',
        subtitle_en: 'Building Search',
        tooltip_en: 'Building Search',
        icon: 'fa fa-folder-magnifying-glass',
        route: '/building-search',
        badge_count: null,
        view_groups: 'PI, PSI'
      }
    ]
  },
  {
    item_type: 'Link',
    title_en: 'Task Inbox',
    icon: 'fa-light fa-arrow-down-to-arc',
    view_groups: 'PI, PSI',
    route: '/task-inbox',
    children: []
  },
  {
    item_type: 'Label',
    title_en: 'MODULES',
    view_groups: null
  },
  {
    item_type: 'Link',
    title_en: 'Digital Forms',
    icon: 'fa-solid fa-book-atlas',
    view_groups: 'PI, PSI',
    route: '/page/dashboard',
    children: []
  },
  {
    item_type: 'Menu',
    title_en: 'Test Controls',
    icon: 'fas fa-gears',
    view_groups: 'PI, PSI',
    children: [
      {
        item_type: 'Link',
        subtitle_en: 'Building Search',
        tooltip_en: 'Building Search',
        icon: 'fa fa-folder-magnifying-glass',
        route: '/building-search',
        badge_count: null,
        view_groups: 'PI, PSI'
      }
    ]
  }
]

export const profileMenuItems: Array<ProfileMenuItem> = [
  {
    type: 'Action',
    text_en: 'Logout',
    icon: 'fa fa-power-off',
    action: () => {
      Logout()
      localStorage.removeItem('api_token')
      authService.authLogout()
      localStorage.removeItem('dwportal_user')
      localStorage.removeItem('dwportal_auth_token')
      window.location.href = `https://logindev.dw.protovate.com/login/${loginPortalId}`
    }
  }
]
