import React, { useEffect, useState } from 'react'
import './AssignManager.scss'
import { FormGroup, Label, Input } from 'reactstrap'

import { AuthService, UserService } from '@digitalworkflow/dwloginclient'

export enum ManagerType {
  project_manager = 'Project Manager',
  move_manager = 'Move Manager',
  fma = 'FMA'
}
interface ManagerProps {
  manager_type?: string
  setIsAssignManagerFormVisible: any
  setManagerName: any
}

const authService = AuthService.instance()
UserService.setAuthServiceInstance(authService)
const userService = new UserService()
const AssignManager = ({
  manager_type,
  setIsAssignManagerFormVisible,
  setManagerName = () => {}
}: ManagerProps) => {
  const [usersList, setUsersList] = useState<any>()

  const getUser = async () => {
    const _result = await userService.getAllUser()
    console.log('Users List ', _result.data)
    setUsersList(_result.data)
  }
  useEffect(() => {
    getUser()
  }, [])

  const handleDropDownChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setManagerName(e.target.value)
  }

  const onAssign = () => {
    setIsAssignManagerFormVisible(false)
  }

  const onCancel = () => {
    setIsAssignManagerFormVisible(false)
  }

  return (
    <div className='Manager-Wrapper'>
      <div className='Manager-Type'>
        <span>{manager_type}</span>
      </div>
      <div className='manager-description'>
        {/* <p>{manager_description}</p> */}
        {manager_type === ManagerType.project_manager ? (
          <p>
            If this Project is 10k to 50k. Please select the Project Manager to
            assign
          </p>
        ) : manager_type === ManagerType.fma ? (
          <p>
            If this Project is under 10k. Click assign to FMA to reassign this
            Project
          </p>
        ) : (
          <p>This is a move related project, please select the Move Manager.</p>
        )}
      </div>
      <div className='manager-dropdown-list'>
        {manager_type === 'Project Manager' ? (
          <FormGroup>
            <Label for='project_manager_select'>
              {ManagerType.project_manager}
            </Label>
            <Input
              id='project_manager_select'
              name='select'
              type='select'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleDropDownChange(e)
              }
            >
              {usersList &&
                usersList.map((user: any) => {
                  return user.first_name !== '' ? (
                    <option
                      key={user.id}
                      value={user.first_name + ' ' + user.last_name}
                    >
                      {user.first_name + ' ' + user.last_name}
                    </option>
                  ) : null
                })}
            </Input>
          </FormGroup>
        ) : manager_type === 'Move Manager' ? (
          <FormGroup>
            <Label for='move_manager_select'>{ManagerType.move_manager}</Label>
            <Input
              id='move_manager_select'
              name='select'
              type='select'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleDropDownChange(e)
              }
            >
              {usersList &&
                usersList.map((user: any) => {
                  return user.first_name !== '' ? (
                    <option
                      key={user.id}
                      value={user.first_name + ' ' + user.last_name}
                    >
                      {user.first_name + ' ' + user.last_name}
                    </option>
                  ) : null
                })}
            </Input>
          </FormGroup>
        ) : null}
      </div>
      <div className='action-buttons'>
        {manager_type === 'FMA' ? (
          <button className='btn-assign'>Reassign</button>
        ) : (
          <button className='btn-assign' onClick={onAssign}>
            Assign
          </button>
        )}
        <button className='btn-cancel' onClick={onCancel}>
          Cancel
        </button>
      </div>
    </div>
  )
}

export default AssignManager
