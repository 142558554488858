import React, { useState } from 'react'
import './attachment.scss'

import { Form, FormGroup, Input, Label } from 'reactstrap'

interface FormState {
  checkbox1: { checked: boolean; text: string }
  checkbox2: { checked: boolean; text: string }
  checkbox3: { checked: boolean; text: string }
  checkbox4: { checked: boolean; text: string }
}

const CheckList = () => {
  const [formState, setFormState] = useState<FormState>({
    checkbox1: {
      checked: false,
      text: 'You have selected ORM as the project type but the building does not appear to have ORM Space'
    },
    checkbox2: {
      checked: false,
      text: 'You have indicated this project is Urgent but did not enter a reason on the Work Details screen'
    },
    checkbox3: {
      checked: false,
      text: 'Urgent Comment is blank and is Mandatory'
    },
    checkbox4: {
      checked: false,
      text: 'External approver Name and/or Date is blank and is Mandatory'
    }
  })

  const handleCheckboxChange = (checkboxName: keyof FormState) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      [checkboxName]: {
        ...prevFormState[checkboxName],
        checked: !prevFormState[checkboxName].checked
      }
    }))
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    // Do something with the form data
    console.log('Form submitted:', formState)
  }
  return (
    <div className='attachment-wrapper'>
      <div className='attachment-upper-section'>
        <h2 className='attachment-title'>
          Project Services Initiation Form Final Checklist
        </h2>
        <p className='attachment-description'>
          Please confirm that everything you have entered for this request is
          accurate. Press the confirm button to submit your request for review.
        </p>
      </div>

      <div className='attachment-lower-section'>
        <div>
          <h2>Potential Problems</h2>
        </div>

        <Form onSubmit={handleSubmit}>
          {Object.entries(formState).map(
            ([checkboxName, { checked, text }]) => (
              <>
                <FormGroup check inline>
                  <Input
                    type='checkbox'
                    checked={checked}
                    onChange={() =>
                      handleCheckboxChange(checkboxName as keyof FormState)
                    }
                    className='red-check-box'
                  />
                  <Label check>{text}</Label>
                </FormGroup>
              </>
            )
          )}
        </Form>
      </div>
    </div>
  )
}

export default CheckList
