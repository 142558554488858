import React, { useState, useEffect } from 'react'
import { DescriptiveButton } from '@digitalworkflow/dwreactcommon'
import './digitalform.scss'
import Breadcrumbs from '../../components/Breadcrumbs'
import ProjectServicesInitiationForm from '../ProjectServicesInitiationForm'
import Tabs from './tabs'
import axios from 'axios'
const DigitalForms = () => {
  const user_DataString = localStorage.getItem('dwportal_user')
  const user_Data = JSON.parse(user_DataString ?? '')
  const hubUserId = user_Data.id
  const [selectedBreadcrumbIndex, setSelectedBreadcrumbIndex] =
    useState<number>(0)
  const [view, setView] = useState('DigitalForm')
  const [workgroupsNames, setWorkgroupsNames] = useState('')
  const [psifData, setPsifData] = useState<any>()
  const [isCompleted, setIsCompleted] = useState(false)
  // const [requestId, setRequestId] = useState()
  const [beginNewPsif, setBeginNewPsif] = useState()

  const [activeStep, setActiveStep] = useState<number>(-1)
  const [reqApiToken, setReqApiToken] = useState('')
  const [breadcrumbItemsList, setBreadcrumbItemsList] = useState([
    {
      text: 'Digital Forms',
      url: '/page/dashboard'
    }
  ])
  const createToken = async () => {
    try {
      const apiUrl = 'https://io.dw.api.protovate.com/api/tokens/create'
      const requestData = {
        email: user_Data?.email,
        first_name: user_Data?.first_name,
        password: user_Data?.password,
        token_name: 'token',
        hub_user_id: hubUserId
      }
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
      const response = await axios.post(apiUrl, requestData, { headers })

      const api_token = response.data.data.token
      if (api_token) {
        localStorage.setItem('api_token', api_token)
        setReqApiToken(api_token)
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const fetchData = async () => {
    try {
      const apiUrl = 'https://io.dw.api.protovate.com/api/modules/9'

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${reqApiToken}`,
          Accept: 'application/json'
        }
      })
      setPsifData(response.data.data)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const handleDescriptiveButton = async (id: number) => {
    try {
      const apiUrl = `https://io.dw.api.protovate.com/api/modules/${id}/child`
      const res = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${reqApiToken}`,
          Accept: 'application/json'
        }
      })
      if (res.status === 200) {
        setBeginNewPsif(res.data.data)
        setView('ProjectServicesInitiation')
      }
    } catch (error) {
      console.log('Error', error)
    }
  }

  useEffect(() => {
    if (user_Data) {
      createToken()
      setWorkgroupsNames(
        user_Data.work_groups.map(
          (workgroup: any) => workgroup.formatted_workgroup_name
        )
      )
    }
  }, [])

  useEffect(() => {
    if (reqApiToken !== '') {
      if (workgroupsNames.includes('BGIS :: PSIF')) {
        fetchData()
      } else if (workgroupsNames.includes('BGIS :: PropUpdate')) {
        setView('Property Update Request')
      } else {
        setView('No Permission')
      }
    }
  }, [reqApiToken])

  const handleBreadcrumbClick = (index: number) => {
    setSelectedBreadcrumbIndex(index)
    switch (index) {
      case 0:
        setView('DigitalForm')
        setBreadcrumbItemsList([
          {
            text: 'Digital Forms',
            url: '/page/dashboard'
          }
        ])
        break
      case 1:
        setView('ProjectServicesInitiation')
        break
      // Add cases for other breadcrumbs as needed
      default:
        setView('DigitalForm')
    }
  }
  return (
    <div className='dgPageWrapper'>
      <Breadcrumbs
        breadcrumbItems={breadcrumbItemsList}
        onBreadcrumbClick={handleBreadcrumbClick}
        selectedBreadcrumbIndex={selectedBreadcrumbIndex}
      />
      {view === 'DigitalForm' && (
        <div className='DescriptiveButtonsContainer'>
          <div className='position-fixed mt-n2'>
            <span>Select a module to begin submitting a request.</span>
          </div>
          {psifData &&
            psifData.length > 0 &&
            psifData.map((d: any) => (
              <span
                key={d.id}
                // onClick={() => setView('ProjectServicesInitiation')}
                onClick={() => handleDescriptiveButton(d.id)}
                className='mt-4'
              >
                <DescriptiveButton
                  variant='success'
                  title={d.module_name}
                  description={d.help_text}
                  classification=''
                />
              </span>
            ))}
        </div>
      )}
      {view === 'ProjectServicesInitiation' && (
        // <span onClick={() => setView('PageTabs')}>
        <ProjectServicesInitiationForm
          beginNewPsif={beginNewPsif}
          setView={setView}
          handleActiveStep={(value: number) => {
            setActiveStep(value)
            setView('PageTabs')
          }}
          setBreadcrumbItemsList={setBreadcrumbItemsList}
          setIsCompleted={setIsCompleted}
        />
        // </span>
      )}

      {view === 'PageTabs' && (
        <>
          <Tabs tabIndex={activeStep} isCompleted={isCompleted} />
        </>
      )}
      {view === 'Property Update Request' && <h2>Property Update Request</h2>}
      {view === 'No Permission' && (
        <h2>You do not have permission to access any modules at this time.</h2>
      )}
    </div>
  )
}

export default DigitalForms
