import React, { useEffect, useState } from 'react'
import { BuildingSearch } from '@digitalworkflow/dwreactcommon'
// import { IBuildingItem } from '@digitalworkflow/dwreactcommon/dist/components/BuildingSearch/interface'
import axios from 'axios'
import './buildinlocation.scss'
interface Props {
  setBuildingItemData: (item: string[]) => void
}
const index = ({ setBuildingItemData }: Props) => {
  const [buildingItemList, setBuildingItemList] = useState<string[]>([])
  const [isError, setIsError] = useState(false)

  const authToken = localStorage.getItem('api_token')
  const handleSelectedItemChange = (items: string[]) => {
    if (items.length > 0) {
      setIsError(false)
    } else {
      setIsError(true)
    }
    setBuildingItemList(items)
    setBuildingItemData(items)
  }

  const startRequestId = localStorage.getItem('CurrentRequestId')
  const getSavedBuildingInfo = async () => {
    const res = await axios.get(
      `https://io.dw.api.protovate.com/api/requests/${startRequestId}/sections/2/view`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`
          // Add any other headers if needed
        }
      }
    )
    if (res.status === 200) {
      setBuildingItemList(res.data.data.data ?? [])
    }
  }
  useEffect(() => {
    getSavedBuildingInfo()
  }, [])

  console.log('Building Data ', buildingItemList)
  return (
    <div className='Building-search'>
      <div>
        <BuildingSearch
          otherFilters='FMZ, City'
          showFav={false}
          showNearest
          minSelect={1}
          maxSelect={10000}
          allowPaste
          showDistance
          templateLine1='%ID% | %BUILDING_NAME%'
          templateLine2='%ADDRESS%'
          onChange={handleSelectedItemChange} // selected  items
          value={buildingItemList} // Ibuilding Item Array
          clientIds='151'
        />
        <br />
        {isError && (
          <span className='errorText'>
            Atleast 1 building should be selected
          </span>
        )}
      </div>
    </div>
  )
}

export default index
