import React, { useState, useEffect } from 'react'
import ContactInformationForm from './Form'
import axios from 'axios'

interface Props {
  setContactData: (item: FormData[]) => void
}
interface FormData {
  contactName: string | undefined
  radioOption: string | undefined
  dropdown: string | undefined
  clientEmail: string | undefined
  phoneNumber: string | undefined
  role: string | undefined
}

const Index: React.FC<Props> = ({ setContactData }) => {
  const [contacts, setContacts] = useState<FormData[]>([])
  const [isEditing, setIsEditing] = useState(false)
  console.log('?????????????????', contacts)
  const [selectedContactIndex, setSelectedContactIndex] = useState<
    number | null
  >(null)
  const [isNewContactFormVisible, setIsNewContactFormVisible] = useState(true) // Set to true initially
  useEffect(() => {
    setContactData(contacts)
  }, [contacts])
  const handleFormSubmit = (data: FormData) => {
    if (isEditing && selectedContactIndex !== null) {
      // Update existing contact
      const updatedContacts = [...contacts]
      updatedContacts[selectedContactIndex] = data
      setContacts(updatedContacts)
      setIsEditing(false)
      setSelectedContactIndex(null)
    } else {
      // Add new contact
      setContacts([...contacts, data])
      // // Close the new contact form after submission
      setIsNewContactFormVisible(false)
    }
  }
  const handleEditClick = (index: number) => {
    setIsEditing(true)
    setSelectedContactIndex(index)
    // Open the edit form and hide existing values
    setIsNewContactFormVisible(false)
  }

  const handleAddNewClick = () => {
    setIsEditing(false)
    setSelectedContactIndex(null)
    // Open the new contact form and hide existing values
    setIsNewContactFormVisible(true)
  }

  const startRequestId = localStorage.getItem('CurrentRequestId')
  const authToken = localStorage.getItem('api_token')

  const getSavedContactInfo = async () => {
    const res = await axios.get(
      `https://io.dw.api.protovate.com/api/requests/${startRequestId}/sections/4/view`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      }
    )
    if (res.status === 200) {
      if (res.data.data.data != null) {
        setContacts(res.data.data.data)
        setIsNewContactFormVisible(false)
      }
      console.log('View Api Res Data', res.data.data.data)
    }
  }
  useEffect(() => {
    getSavedContactInfo()
  }, [])

  return (
    <div className='digital-form-contact-information-display'>
      {contacts &&
        !isNewContactFormVisible &&
        !isEditing &&
        contacts.map((contact, index) => (
          <div key={index} className='data-filled-form'>
            <div className='wrapper'>
              <div>
                <label>Client Contact Name:</label>{' '}
                <h2>{contact.contactName}</h2>
              </div>
              {index !== 0 ? (
                <div>
                  <label>Role for this Request:</label> <h2>{contact.role}</h2>
                </div>
              ) : (
                <div>
                  <label>Client Group Name:</label>{' '}
                  <h2>
                    {contact.radioOption === 'MIN'
                      ? 'MIN-Ministry'
                      : contact.dropdown}
                  </h2>
                </div>
              )}
              <div>
                <label>Client Contact Email Address:</label>{' '}
                <h2>{contact.clientEmail}</h2>
              </div>
              <div>
                <label>Client Contact Phone Number:</label>{' '}
                <h2>{contact.phoneNumber}</h2>
              </div>
            </div>
            <button onClick={() => handleEditClick(index)} className='edit-btn'>
              Edit
            </button>
          </div>
        ))}

      {(isEditing || isNewContactFormVisible) && (
        <ContactInformationForm
          onSubmit={handleFormSubmit}
          isNewContactFormVisible={isNewContactFormVisible}
          isEditing={isEditing}
          contacts={contacts}
          selectedContactIndex={selectedContactIndex}
          initialValues={
            selectedContactIndex !== null
              ? contacts[selectedContactIndex]
              : undefined
          }
        />
      )}

      {!isEditing && !isNewContactFormVisible && contacts.length > 0 && (
        <div className='add-new-contact-section'>
          <p>
            If there are additional contacts that you wish to include on this
            request, you may add them now.{' '}
          </p>
          <button onClick={handleAddNewClick} className='add-contact-btn'>
            Add New Contact
          </button>
        </div>
      )}
    </div>
  )
}

export default Index
