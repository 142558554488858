import axios from 'axios'

const Logout = () => {
  const authToken = localStorage.getItem('api_token')

  const getLogout = async () => {
    await axios.post(
      'https://io.dw.api.protovate.com/api/tokens/revoke',
      {},
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
  }

  getLogout()
}

export default Logout
