import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'reactstrap'

import { BreadcrumbItem } from '../types/breadcrumbs'

import './BreadCrumb.scss'

interface Props {
  breadcrumbItems: BreadcrumbItem[]
  onBreadcrumbClick: (index: number) => void
  selectedBreadcrumbIndex: number
}

const BreadCrumbs = ({
  breadcrumbItems,
  onBreadcrumbClick,
  selectedBreadcrumbIndex
}: Props) => {
  const handleBreadcrumbClick = (index: number) => {
    onBreadcrumbClick(index)
  }

  return (
    <Row>
      <Col xs='12'>
        <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
          <span className='mb-0 bread-class'>
            {breadcrumbItems.map((breadcrumbItem, i) => {
              const isSelected = i === selectedBreadcrumbIndex
              const linkClass = isSelected
                ? 'active-breadcrumb'
                : 'breadcrumb-link'
              return (
                <React.Fragment key={i}>
                  <Link
                    to={breadcrumbItem.url}
                    onClick={() => handleBreadcrumbClick(i)}
                    className={linkClass}
                  >
                    {breadcrumbItem.text}
                  </Link>
                  {i < breadcrumbItems.length - 1 && (
                    <span>&nbsp;&gt;&nbsp;</span>
                  )}
                </React.Fragment>
              )
            })}
          </span>
        </div>
      </Col>
    </Row>
  )
}

export default BreadCrumbs
