import React, { useEffect } from 'react'

import { useLocation } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'

/**
 * IdleWatch component.
 *
 * @remarks
 * Log the user out if token does not exist
 *
 * @component IdleWatch
 * @category Component
 */
const IdleWatch = () => {
  const { logout } = useAuth()
  const location = useLocation()

  const checkAuth = () => {
    const token = localStorage.getItem('dwportal_auth_token')
    if (!token) {
      logout()
    }
  }
  useEffect(() => {
    checkAuth()
  }, [location.pathname])

  useEffect(() => {
    const eventListener = () => {
      checkAuth()
    }
    document.addEventListener('click', eventListener)
    document.addEventListener('keydown', eventListener)

    return () => {
      document.removeEventListener('click', eventListener)
      document.removeEventListener('keydown', eventListener)
    }
  }, [])

  return <React.Fragment />
}

export default IdleWatch
