import React, { useEffect, useState } from 'react'
import './CustomDescriptiveButton.scss'
interface CustomDescriptiveButtonInterface {
  title: string
  request_id: number
  project_name: string
  client_reference_number: string
  client_building_code: string
  changes: number
  last_updated: string
  handleActiveRequest: () => void
  isCompleted?: boolean
}

const formatTime = (timestamp: string) => {
  const date = new Date(timestamp)
  const hours = date.getUTCHours()
  const minutes = date.getUTCMinutes()

  let formattedTime = ''

  if (hours > 0) {
    formattedTime += `${hours}hrs, `
  }

  if (minutes > 0 || formattedTime === '') {
    formattedTime += `${minutes}min`
  }

  return formattedTime
}
const CustomDescriptiveButton = ({
  title,
  request_id,
  project_name,
  client_reference_number,
  client_building_code,
  changes,
  last_updated,
  handleActiveRequest,
  isCompleted = false
}: CustomDescriptiveButtonInterface) => {
  const [userData, setUserData] = useState<any>()
  console.log('value', handleActiveRequest)
  const time = formatTime(last_updated)
  console.log('id', localStorage.getItem('requestId'))
  useEffect(() => {
    const userString = localStorage.getItem('dwportal_user')
    if (userString !== null) {
      setUserData(JSON.parse(userString))
    }
  }, [])

  return (
    <div className='custom-descriptive-button' onClick={handleActiveRequest}>
      {/* <div className='left-color-bar'></div> */}
      <div
        className={` ${isCompleted ? 'left-color-grey-bar' : 'left-color-bar'}`}
      ></div>
      <div className='right-content-area'>
        <div className='top-content'>
          <div className='top-left-content'>
            <span className='request-heading'>{title}</span>
            <div className='top-span-content-div'>
              <span className='content-span'>Project Name:</span>
              <span className='content-value'>{project_name}</span>
            </div>
            <div className='top-span-content-div'>
              <span className='content-span'>Client Reference Number:</span>
              <span className='content-value'>{client_reference_number}</span>
            </div>
          </div>
          <div className='top-right-content'>
            <span className='request-number'>#{request_id}</span>
          </div>
        </div>
        <div className='mid-content'>
          <div className='building-code'>
            <span className='content-span'>Client Building Code</span>
            <span className='content-value'>{client_building_code}</span>
          </div>
          <div className='divider'></div>
          <div className='changes'>
            <span className='content-span'>Changes</span>
            <span className='content-value'>{changes}</span>
          </div>
          <div className='divider'></div>
          <div className='last-updated'>
            <span className='content-span'>Last Updated</span>
            <span className='content-value'>
              {userData &&
                userData.first_name +
                  ' ' +
                  userData.last_name.charAt(0) +
                  ' - ' +
                  time}
            </span>
          </div>
        </div>
        <div className='lower-content'>
          {isCompleted ? (
            <span className='team-name'>
              This task has been marked{' '}
              <span className='accepted'> Accepted </span> and is now closed
            </span>
          ) : (
            <span className='team-name'>
              This task is assigned to BGIS Project Intake Team.
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default CustomDescriptiveButton
