import React, { useEffect, useState } from 'react'
import './tabs.scss'

import { Notepad } from '@digitalworkflow/dwreactcommon'

import Details from './components/Details'
import Welcome from './components/Welcome'
import BuildingLocation from './components/BuildingLocation'
import ContactInformation from './components/ContactInformation'
import WorkDetails from './components/WorkDetails'
import Attachments from './components/Attachments/Attachments'

import StepProgress from '../../../components/PageEngine/StepProgress'

import axios from 'axios'
// import { IBuildingItem } from '@digitalworkflow/dwreactcommon/dist/components/BuildingSearch/interface'
// import FormData from 'form-data'

import { TbMathGreater } from 'react-icons/tb'
// import Details from "./components/Details";

interface IFormData {
  contactName: string | undefined
  radioOption: string | undefined
  dropdown: string | undefined
  clientEmail: string | undefined
  phoneNumber: string | undefined
}
interface IIndex {
  tabIndex?: number
  isCompleted?: boolean
}
let tabStack: any[] = []
const index = ({ tabIndex, isCompleted }: IIndex) => {
  const [buildingItemData, setBuildingItemData] = useState<string[]>([])
  const [contactData, setContactData] = useState<IFormData[]>([])
  const [activeTab, setActiveTab] = useState(0)
  const [filesData, setFilesData] = useState<any[]>([])
  // const [fileList, setFileList] = useState<any>([])
  const [activeComponent, setActiveComponent] = useState('FileAttachment')
  const startRequestId = localStorage.getItem('CurrentRequestId')
  const authToken = localStorage.getItem('api_token')

  useEffect(() => {
    if (tabIndex && tabIndex > -1) {
      setActiveTab(tabIndex)
    }
  }, [tabIndex])

  const handleAddNote = async () => {
    try {
      const data = JSON.stringify({
        note: 'Request Submitted'
      })
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `https://io.dw.api.protovate.com/api/notes/${startRequestId}/request`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        data: data
      }

      axios
        .request(config)
        .then((response: any) => {
          console.log(JSON.stringify(response.data))
        })
        .catch((error: any) => {
          console.log(error)
        })
    } catch (error) {
      console.error('Error:', error)
    }
  }
  const saveBuildingInfo = async (
    id?: number,
    section_id?: number,
    dataa?: any
  ): Promise<boolean> => {
    try {
      const data = JSON.stringify({
        data: dataa
      })

      console.log(id, section_id, dataa)

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `https://io.dw.api.protovate.com/api/requests/${startRequestId}/sections/2/save`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        data: data
      }

      axios
        .request(config)
        .then((response: any) => {
          console.log(JSON.stringify(response.data))
        })
        .catch((error: any) => {
          console.log(error)
        })

      return false
    } catch (error) {
      // Handle errors if needed
      console.error('Error:', error)
      return false
    }
  }
  const handleRequestSubmitted = async () => {
    saveFileAttachmentData()
    setActiveComponent('checkList')
    if (activeComponent === 'FileAttachment') await handleAddNote()
  }
  const handleTabClick = async (index: number) => {
    let res = false
    if (index === 2) {
      res = await saveBuildingInfo(1, 1, buildingItemData)
    }

    if (index === 3) {
      res = await postContactInfo(1, 1, contactData)
    }
    if (index === 1 && res === true) {
      setActiveTab(index)
    }
    setActiveTab(index)
  }
  const renderTabs = () => {
    const tabs = [
      'Welcome',
      'Building Location',
      'Contact Information',
      'Work Details',
      'Attachments'
    ]
    const completedTabs = [
      'Details',
      'Building Location',
      'Contact Information',
      'Work Details',
      'Attachments'
    ]
    console.log('completed', isCompleted)
    let tabsToRender: any[] = []
    if (isCompleted) {
      tabStack = [0, 1, 2, 3, 4]
    }
    if (tabStack.indexOf(activeTab) === -1) {
      tabsToRender = Array.from({ length: activeTab + 1 }, (_, index) => index)
      tabStack = tabsToRender
    }

    return tabStack.map((index) => (
      <div
        key={index}
        // className={`tab ${index === activeTab ? 'active' : ''} ${
        //   index === activeTab ? `tab${index}` : ''
        // }`}
        className={`tab ${index === activeTab ? 'active' : ''} ''`}
        onClick={() => handleTabClick(index)}
        // onClick={() => handleTabClick(index)}
      >
        {!isCompleted ? tabs[index] : completedTabs[index]}
        <div className='divider'></div>
      </div>
    ))
  }
  const [trackingId, setTrackingId] = useState('')
  const ConfirmRequestUpdateApi = async (): Promise<boolean> => {
    try {
      const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `https://io.dw.api.protovate.com/api/requests/${startRequestId}/status`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        data: {
          status: 'completed'
        }
      }

      axios
        .request(config)
        .then((response: any) => {
          console.log(JSON.stringify(response.data))
          setTrackingId(response.data.data.number)
        })
        .catch((error: any) => {
          console.log(error)
        })
      return true
    } catch (error) {
      console.log('Error:', error)
      return false
    }
  }
  console.log('completed', isCompleted)
  console.log('activetabs', activeTab)
  console.log('stacktabs,', tabStack)
  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return isCompleted ? <Details /> : <Welcome />
      case 1:
        return <BuildingLocation setBuildingItemData={setBuildingItemData} />
      case 2:
        return <ContactInformation setContactData={setContactData} />
      case 3:
        return <WorkDetails nextTab={handleTabClick} />
      case 4:
        return (
          <Attachments
            setFilesData={setFilesData}
            activeComponent={activeComponent}
            tracking_id={trackingId}
          />
        )
      default:
        return null
    }
  }

  const postContactInfo = async (
    id?: number,
    section_id?: number,
    dataa?: IFormData[]
  ) => {
    try {
      console.log(id, section_id, dataa)
      const data = JSON.stringify({
        data: dataa
      })

      const startRequestId = localStorage.getItem('CurrentRequestId')

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `https://io.dw.api.protovate.com/api/requests/${startRequestId}/sections/4/save`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        data: data
      }

      await axios
        .request(config)
        .then((response: any) => {
          console.log(response.data)
        })
        .catch((error: any) => {
          console.log(error)
        })

      return false
    } catch (error) {
      // Handle errors if needed
      console.error('Error:', error)
      return false
    }
  }
  const saveFileAttachmentData = async (): Promise<boolean> => {
    try {
      const URLs = filesData.map((file: any) => file)
      const payload = JSON.stringify({
        data: URLs
      })

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `https://io.dw.api.protovate.com/api/requests/${startRequestId}/sections/5/save`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        data: payload
      }

      axios
        .request(config)
        .then((response: any) => {
          console.log(JSON.stringify(response.data))
        })
        .catch((error: any) => {
          console.log(error)
        })

      return false
    } catch (error) {
      // Handle errors if needed
      console.error('Error:', error)
      return false
    }
  }

  useEffect(() => {
    console.log({ activeComponent })
  }, [activeComponent])

  // @ts-ignore
  return (
    <div className='digital-form-tab'>
      <div className='d-flex align-items-center justify-content-between'>
        <div className='progress-steps'>
          <StepProgress
            Btn_Name='Submit Form'
            type='progress'
            current='current'
          />
          <TbMathGreater color='#6B96B5' />
          <StepProgress Btn_Name='PS Review' type='progress' />
          <TbMathGreater color='#6B96B5' />
          <StepProgress Btn_Name='Accepted' type='progress' />
        </div>
        <div className='share-step'>
          <StepProgress Btn_Name='Share' type='share' />
        </div>
      </div>

      <div className='tabs-container'>{renderTabs()}</div>
      <div className='tab-content-wrapper'>
        <div
          // className='tab-content'
          // style={{ width: activeTab !== 0 && '65%' }}
          style={{
            width:
              activeTab !== 0 &&
              activeComponent !== 'checkList' &&
              activeComponent !== 'SubmissionSucces'
                ? '65%'
                : '100%'
          }}
        >
          {renderTabContent()}
        </div>
        {activeTab !== 0 &&
          activeComponent !== 'checkList' &&
          activeComponent !== 'SubmissionSucces' && (
            <div className='notepad'>
              <Notepad
                id='1234'
                title='Task Notepad'
                projectId=''
                objectType=''
                relatedObject=''
                createdBy=''
                updatedBy=''
              />
            </div>
          )}
      </div>

      <div className='start-request-btn'>
        {activeTab === 0 && !isCompleted && (
          <button onClick={() => handleTabClick(1)}>
            <i className='fa-regular fa-check-square font-size-20 align-middle me-2'></i>
            Start Request
          </button>
        )}
        {activeTab === 1 && !isCompleted && (
          <button
            className={buildingItemData.length === 0 ? 'disabled' : ''}
            disabled={buildingItemData.length === 0}
            onClick={() => handleTabClick(2)}
          >
            <i className='fa fa-sign-in' aria-hidden='true'></i>&nbsp;Next
          </button>
        )}
        {activeTab === 2 && !isCompleted && (
          <button
            className={contactData.length === 0 ? 'disabled' : ''}
            disabled={contactData.length === 0}
            onClick={() => handleTabClick(3)}
          >
            <i className='fa fa-sign-in' aria-hidden='true'></i>&nbsp;Next
          </button>
        )}
        {activeTab === 4 &&
          !isCompleted &&
          activeComponent !== 'checkList' &&
          activeComponent !== 'SubmissionSucces' && (
            <button onClick={handleRequestSubmitted}>
              <svg
                width='24'
                height='19'
                viewBox='0 0 24 19'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                {' '}
                <path
                  d='M6.375 2.625V13.5625H17.625V2.625H6.375ZM4.5 13.5625V2.625C4.5 1.60938 5.32031 0.75 6.375 0.75H17.625C18.6406 0.75 19.5 1.60938 19.5 2.625V13.5625C20.0078 13.5625 20.4375 13.9922 20.4375 14.5C20.4375 15.0469 20.0078 15.4375 19.5 15.4375H17.625H6.375H4.5C3.95312 15.4375 3.5625 15.0469 3.5625 14.5C3.5625 13.9922 3.95312 13.5625 4.5 13.5625ZM3.25 10.75V12.625H2.625V16.375H21.375V12.625H20.75V10.75H21.375C22.3906 10.75 23.25 11.6094 23.25 12.625V16.375C23.25 17.4297 22.3906 18.25 21.375 18.25H2.625C1.57031 18.25 0.75 17.4297 0.75 16.375V12.625C0.75 11.6094 1.57031 10.75 2.625 10.75H3.25ZM15.7891 6.53125L11.6094 10.7109C11.4141 10.8672 11.1797 10.9844 10.9453 10.9844C10.6719 10.9844 10.4375 10.8672 10.2812 10.7109L8.21094 8.60156C7.82031 8.25 7.82031 7.66406 8.21094 7.3125C8.5625 6.92188 9.14844 6.92188 9.5 7.3125L10.9453 8.71875L14.4609 5.20312C14.8125 4.85156 15.3984 4.85156 15.75 5.20312C16.1406 5.59375 16.1406 6.17969 15.75 6.53125H15.7891Z'
                  fill='white'
                />
              </svg>
              {'  '}
              {activeComponent === 'checkList'
                ? 'Confirm Request'
                : 'Submit Request'}
            </button>
          )}
        {activeComponent === 'checkList' && !isCompleted && (
          <button
            onClick={() => {
              ConfirmRequestUpdateApi()
              setActiveComponent('SubmissionSucces')
            }}
          >
            Confirm Request
          </button>
        )}
      </div>
    </div>
  )
}

export default index
