import React from 'react'
import { GoDash } from 'react-icons/go'
import './index.scss'

// import dash from '../../../assets/icons/dash.svg'
// import circle from '../../../assets/icons/circle.svg'
// import circlechecked from '../../../assets/icons/circle-checked.svg'
import share_icon from '../../../assets/icons/share.svg'

import { FaRegCircle } from 'react-icons/fa'

const StepProgress = ({ Btn_Name, type, current }: any) => {
  // const [iconPath, setIconPath] = useState('')
  //
  // useEffect(() => {
  //   switch (activeTab) {
  //     case 0:
  //       setIconPath(dash)
  //       break
  //     case 1:
  //       setIconPath(dash)
  //       break
  //     case 2:
  //       setIconPath(dash)
  //       break
  //     case 3:
  //       setIconPath(dash)
  //       break
  //     case 4:
  //       setIconPath(dash)
  //       break
  //     default:
  //       setIconPath('') // Set a default value if needed
  //   }
  // }, [activeTab])

  return (
    <div className={`Step-Block ${current}`}>
      <div
        className={`left-${
          type && type === 'progress' ? 'color' : 'no-color'
        }-block`}
      ></div>
      <div className='content-block'>{Btn_Name}</div>
      <div className='icon'>
        {type && type === 'progress' ? (
          current === 'current' ? (
            <FaRegCircle color='#00A6B6' />
          ) : (
            <GoDash color='#00A6B6' />
          )
        ) : (
          <img src={share_icon} alt='icon' />
        )}
      </div>
    </div>
  )
}

export default StepProgress
