import React from 'react'
import FileIcon from './FileIcon'
import moment from 'moment'
import './FileAttachment.module.scss'
interface IFileItem {
  file: any
  removeFile: () => void
}

const FileItem = ({ file, removeFile }: IFileItem) => {
  return (
    <div className='file'>
      <div className='d-flex gap-2 align-items-center'>
        <FileIcon type={file.extension ?? ''} />
        <div className='file-name-container'>
          <p className='file-name'>{file.fileName}</p>
          <p className='last-updated' style={{ fontSize: '12px' }}>
            Last Updated:{' '}
            {moment(file.lastUpdated, 'YYYY-MM-DD HH:mm:ss').format(
              'MMMM Do YYYY [at] h:mm A'
            )}{' '}
            by <span style={{ color: 'blue' }}>{'@' + file.name}</span>
          </p>
        </div>
      </div>
      <div className='d-flex gap-2 align-items-center'>
        <a href={file.url} target='_blank' rel='noreferrer'>
          <i className='fa-light fa-cloud-arrow-down downloadIcon' />
        </a>

        <div onClick={removeFile}>
          <i className='fa-solid fa-xmark icon' />
        </div>
      </div>
    </div>
  )
}
export default FileItem
