import React, { useEffect, useState } from 'react'

import './Details.scss'
import AssignManager, { ManagerType } from './AssignManager'
import axios from 'axios'

const Details = () => {
  const [acknowledgeState, setAcknowledgeState] = useState(false)
  const [acknowledgeDateTime, setAcknowledgeDateTime] = useState(new Date())
  const [isAssignManagerFormVisible, setIsAssignManagerFormVisible] =
    useState(false)
  const [managerTypeState, setManagerTypeState] = useState('')

  const [userName, setUserName] = useState('')
  const [managerName, setManagerName] = useState('')
  const [requestObject, setRequestObject] = useState<any>({})
  const [requestCreatedAt, setRequestCreatedAt] = useState<any>()
  const formatDateTime = (date: Date) => {
    const options: any = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }

    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date)

    // Add ordinal suffix to the day
    const day = date.getDate()
    const suffix =
      day >= 11 && day <= 13
        ? 'th'
        : ['st', 'nd', 'rd', 'th'][(day % 10) - 1] || 'th'

    return formattedDate.replace(/\d+/, (day) => `${day}${suffix}`)
  }

  const authToken = localStorage.getItem('api_token')
  const reqId = localStorage.getItem('ReqId')

  const updateRequestStatus = async (id: any) => {
    try {
      await axios
        .put(`https://io.dw.api.protovate.com/api/requests/${id}/status`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`
          }
        })
        .then((response) => {
          if (response.status === 200) {
            console.log('Status Updated')
          }
        })
    } catch (e) {
      console.log('Error', e)
    }
  }
  const HandleAcknowledgeButtonClick = () => {
    setAcknowledgeState(true)
    setAcknowledgeDateTime(new Date())
    updateRequestStatus(reqId)
  }

  const AssignPMBtn = () => {
    setIsAssignManagerFormVisible(true)
    setManagerTypeState(ManagerType.project_manager)
  }

  const AssignFMABtn = () => {
    setIsAssignManagerFormVisible(true)
    setManagerTypeState(ManagerType.fma)
  }

  const AssignMoveManagerBtn = () => {
    setIsAssignManagerFormVisible(true)
    setManagerTypeState(ManagerType.move_manager)
  }

  const RequestId = localStorage.getItem('RequestId')
  const getRequests = async () => {
    console.log('RequestId', reqId)
    await axios
      .get('https://io.dw.api.protovate.com/api/requests', {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      })
      .then((response) => {
        const responseData = response.data.data

        // Find the object with id equal to 5
        const desiredObject = responseData.find(
          (obj: any) => obj.number === RequestId
        )
        console.log('Api resp details page', response.data.data)
        setRequestObject(desiredObject)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    getRequests()
  }, [])
  useEffect(() => {
    // Log when requestObject changes
    console.log('desired Object', requestObject)
    setRequestCreatedAt(
      new Date(requestObject?.created_at).toLocaleString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
      })
    )

    // ...rest of the code
  }, [requestObject])

  useEffect(() => {
    const user = localStorage.getItem('dwportal_user')
    if (user) {
      const userJson = JSON.parse(user)
      setUserName(userJson.first_name + ' ' + userJson.last_name)
    }
  }, [])

  return (
    <div className='Main-Wrapper'>
      {isAssignManagerFormVisible ? (
        <AssignManager
          manager_type={managerTypeState}
          setIsAssignManagerFormVisible={setIsAssignManagerFormVisible}
          setManagerName={setManagerName}
        />
      ) : null}
      {!isAssignManagerFormVisible ? (
        <>
          <div className='Top-Wrapper'>
            <span>Task Details</span>
            <div className='Task-Details'>
              <div className='label-item'>
                <label>Task Created By:</label> <h2>{userName}</h2>
              </div>
              <hr />
              <div className='label-item'>
                <label>Task Creation Date:</label>
                <h2>{requestCreatedAt && requestCreatedAt}</h2>
              </div>
              <hr />
              <div className='label-item'>
                <label>Last Updated</label> <h2>Never</h2>
              </div>

              <hr />
              <div className='label-item'>
                <label>Last Updated By:</label>
                <h2>Nobody</h2>
              </div>

              <hr />
              <div className='label-item'>
                <label>Task Assigned To:</label>
                {managerName && managerName !== '' ? (
                  <h2>{managerName}</h2>
                ) : (
                  <h2>BGIS :: Property Services :: PSIF Intake</h2>
                )}
              </div>

              <hr />
              <div className='label-item'>
                <label>Task Acknowledged:</label>
                {acknowledgeState && acknowledgeState ? (
                  <h2>
                    {acknowledgeDateTime && formatDateTime(acknowledgeDateTime)}
                  </h2>
                ) : (
                  <h2>Never</h2>
                )}
              </div>
            </div>
            <div
              className={`${
                acknowledgeState && acknowledgeState
                  ? 'acknowledged'
                  : 'acknowledgment'
              }-class`}
            >
              {!acknowledgeState ? (
                <>
                  <p>
                    This task has not yet been accepted. Click the “Acknowledge”
                    button to assign this to yourself.
                  </p>
                  <button
                    type='button'
                    className='Acknowledge-Button'
                    onClick={HandleAcknowledgeButtonClick}
                  >
                    Acknowledge
                  </button>
                </>
              ) : (
                <p>This task has been acknowledged.</p>
              )}
            </div>
          </div>
          <div className='Bottom-Wrapper'>
            <div className='Next-Step'>
              <div className='label-item'>
                <label>Next Step Name:</label>
                <h2>Acknowledge the task to begin work</h2>
              </div>
              <hr />
              <div className='label-item'>
                <label>Next Step Due:</label>
                <h2>Tuesday, October 31st, 2023 at 4:20:02pm</h2>
              </div>
            </div>
            <div>
              <button className='Postpone-Button'>Postpone</button>
            </div>
          </div>
          {!isAssignManagerFormVisible &&
          acknowledgeState &&
          acknowledgeState ? (
            <div className='available-actions-wrapper'>
              <div className='top-p'>
                <p>Available Actions for this step.</p>
              </div>
              <div className='available-actions-buttons'>
                <button className='btn-close-task'>Close Task</button>
                <button className='btn-assign-pm' onClick={AssignPMBtn}>
                  Assign PM
                </button>
                <button className='btn-assign-fma' onClick={AssignFMABtn}>
                  Assign FMA
                </button>
                <button
                  className='btn-assign-move-manager'
                  onClick={AssignMoveManagerBtn}
                >
                  Assign Move Manager
                </button>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  )
}

export default Details
