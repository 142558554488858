import React, { useEffect, useState } from 'react'
import { DescriptiveButton } from '@digitalworkflow/dwreactcommon'
import './psi.scss'
import axios from 'axios'
import CustomDescriptiveButton from '../../components/Custom Descriptive Button/CustomDescriptiveButton'

const ProjectServicesInitiationForm = ({
  beginNewPsif,
  setView,
  handleActiveStep,
  setBreadcrumbItemsList,
  setIsCompleted
}: any) => {
  const [activeRequests, setActiveRequests] = useState<any>()
  const authToken = localStorage.getItem('api_token')
  const handleButtonCLick = async () => {
    setView('PageTabs')
    const data = JSON.stringify({
      project_id: 9,
      module_id: 2
    })

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://io.dw.api.protovate.com/api/requests/start',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      },
      data: data
    }

    axios
      .request(config)
      .then((response) => {
        localStorage.setItem('CurrentRequestId', response.data.data.id)
        console.log(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getRequests = async () => {
    const res = await axios.get(
      'https://io.dw.api.protovate.com/api/requests',
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    )
    if (res.status === 200) {
      console.log('Res active Requests', res.data.data)
      setActiveRequests(res.data.data)
    }
  }

  useEffect(() => {
    getRequests()
    setBreadcrumbItemsList((prevState: any) => {
      const isObjectExist = prevState.some((item: any) => {
        return item.text === 'Project Services Initiation Form (PSIF)'
      })

      if (!isObjectExist) {
        return [
          ...prevState,
          {
            text: 'Project Services Initiation Form (PSIF)',
            url: '/page/dashboard'
          }
        ]
      }

      return prevState
    })
  }, [])

  const handleActiveRequest = (value: any) => {
    console.log('value', value)
    handleActiveStep(value === null ? 0 : value)
  }
  const handleCompleteRequest = (value: any) => {
    console.log('value', value)
    setIsCompleted(true)
    handleActiveStep(value === null ? 0 : value)
  }

  const handleCompletedRequestCLick = (reqNumber: any, reqId: any) => {
    localStorage.setItem('RequestId', reqNumber)
    localStorage.setItem('ReqId', reqId)
  }

  return (
    <div className='psiPageWrapper'>
      <span className='SubHeading'>
        Select an option to begin submitting a Project Services Initiation Form
        or reviewing previous request.
      </span>
      <div className='DescriptiveButtonsContainer'>
        {beginNewPsif &&
          beginNewPsif.length > 0 &&
          beginNewPsif.map((d: any, key: number) => (
            <span
              key={key}
              onClick={handleButtonCLick}
              // onClick={() => handleDescriptiveButton(d.id)}
            >
              <DescriptiveButton
                variant='success'
                title={d.module_name}
                description={d.help_text}
                classification=''
              />
            </span>
          ))}
      </div>
      <div className='active-completed-requests'>
        <h2 className='Requests-Headings'>Active Requests</h2>
        <div className='request-tiles'>
          {activeRequests &&
            activeRequests.length > 0 &&
            activeRequests.map((req: any, key: number) =>
              req.status === 'in_progress' ? (
                <span
                  key={key}
                  // onClick={handleButtonCLick}
                  // onClick={() => handleDescriptiveButton(d.id)}
                >
                  <CustomDescriptiveButton
                    title={req.project.name_en}
                    project_name='My Project'
                    client_reference_number={req.number}
                    request_id={12234}
                    client_building_code='IN0071'
                    changes={5}
                    last_updated={req.project.updated_at}
                    handleActiveRequest={() =>
                      handleActiveRequest(req.last_updated_section_id)
                    }
                  />
                </span>
              ) : null
            )}
        </div>
      </div>

      <div className='active-completed-requests'>
        <h2 className='Requests-Headings'>Completed Requests</h2>
        <div className='request-tiles'>
          {activeRequests &&
            activeRequests.length > 0 &&
            activeRequests.map((req: any, key: number) =>
              req.status === 'completed' || req.status === 'Acknowledged' ? (
                <span
                  key={key}
                  onClick={() =>
                    handleCompletedRequestCLick(req.number, req.id)
                  }
                  // onClick={() => handleDescriptiveButton(d.id)}
                >
                  <CustomDescriptiveButton
                    title={req.project.name_en}
                    project_name='My Project'
                    client_reference_number={req.number}
                    request_id={12234}
                    client_building_code='IN0071'
                    changes={5}
                    last_updated={req.project.updated_at}
                    handleActiveRequest={() => handleCompleteRequest(0)}
                    isCompleted
                  />
                </span>
              ) : null
            )}
        </div>
      </div>
    </div>
  )
}

export default ProjectServicesInitiationForm
