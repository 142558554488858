import React, { useEffect, useState } from 'react'
import './work-details.scss'

import axios from 'axios'
import { Form, Input, Label, FormGroup } from 'reactstrap'

import { useFormik } from 'formik'
import * as yup from 'yup'
import infoIcon from '../../../../../assets/icons/info-icon.svg'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'

const checkboxOptions = [
  'Workstation reconfiguration – dismantle/move/reinstall as required. Move/relocation of freestanding furniture – chairs, files, tables, case goods, etc.',
  'Move/relocation of freestanding furniture – chairs, files, tables, case goods, etc.',
  'Filing cabinet/storage cabinet and contents moves.',
  'Box moves of files and personal belongings.',
  'Packing/unpacking services.',
  'Removal of furniture from site – reuse/recycle/disposal.',
  'Other'
]

const initialValues = {
  typeReqValue: '',
  clientApproveValue: '',
  psif: '',
  projectName: '',
  urgency: '',
  externalApprover: '',
  numberInput: 0,
  reqCompletionDate: '',
  approvelDate: '',
  isUrgent: '',
  isSensitiveInfo: '',
  workScope: '',
  additionalInformation: '',
  checkboxList: Array(checkboxOptions.length).fill(false)
}
// @ts-ignore
const validationSchema = yup.object({
  typeReqValue: yup.string().required('* Please Select an Option'),
  clientApproveValue: yup.string().required('* Please Select an Option'),
  psif: yup.string().required('*Please Enter Client Reference Number'),
  projectName: yup
    .string()
    .required('*Please Enter Project Name')
    .max(150, '*Project Name must not exceed 150 characters.'),
  isSensitiveInfo: yup.string().required('* Please Select an Option'),
  isUrgent: yup.string().required('* Please Select an Option'),
  urgency: yup.string().when('isUrgent', {
    is: 'Yes',
    then: () => yup.string().required('*Please Enter Reason for Urgency')
  }),
  workScope: yup.string().when('typeReqValue', {
    is: 'Small Works',
    then: () => yup.string().required('*Please Enter Scope of the Work')
  }),
  externalApprover: yup.string().when('clientApproveValue', {
    is: 'No, an external approver is needed',
    then: () =>
      yup
        .string()
        .required("*Please Enter External Approver's name")
        .matches(
          /^[a-zA-Z]+(?: [a-zA-Z]+)*$/,
          '*Name Should only Contain alphabets'
        )
  }),
  checkboxList: yup
    .array()
    .of(yup.boolean())
    .when('typeReqValue', {
      is: 'Move Services',
      then: () =>
        yup.array().test('allFalse', '*Please Select an Option', (value) => {
          const foundItem = value && value.find((item) => item === true)
          if (foundItem) {
            return true
          } else {
            return false
          }
        })

      // .required("*Please Select an Option"),
    }),
  additionalInformation: yup.string().when(['typeReqValue', 'checkboxList'], {
    is: (typeReqValue: any, checkboxList: any) =>
      typeReqValue === 'Move Services' &&
      checkboxList &&
      checkboxList.length > 0 &&
      checkboxList[checkboxList.length - 1] === true,
    then: () =>
      yup
        .string()
        .test(
          'lastCheckboxTrue',
          '*Please Enter Additional Information',
          (value: any) => {
            // Check if the additionalInformation is provided when the last checkbox is true
            return value && value.trim() !== ''
          }
        )
        .required('*Please Enter Additional Information')
  }),
  numberInput: yup.number().required('*Please Enter Funding Approval Value'),
  reqCompletionDate: yup.date().required('*Please Select a Date'),
  approvelDate: yup
    .date()
    .max(
      new Date(),
      '*Approval date must be less than or equal to the current date'
    )
    .when('clientApproveValue', {
      is: 'No, an external approver is needed',
      then: () => yup.date().required('*Please Select a Date')
    })
})
interface IProps {
  nextTab: (value: number) => void
}
const MyForm = ({ nextTab }: IProps) => {
  const [initValue, setInitValue] = useState(initialValues)

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    resetForm
  } = useFormik({
    enableReinitialize: true,
    initialValues: initValue,
    validationSchema: validationSchema,
    onSubmit: () => {
      postWorkDetails(values)
    }
  })

  const typeReqRadioOptions = ['Small Works', 'Move Services']
  const clientApproveRadioOptions = [
    'Yes',
    'No, an external approver is needed'
  ]
  const radioOptions = ['No', 'Yes']
  const authToken = localStorage.getItem('api_token')
  const startRequestId = localStorage.getItem('CurrentRequestId')

  const postWorkDetails = async (values: any) => {
    try {
      if (values.typeReqValue === 'Move Services' && values.workScope !== '') {
        values.workScope = ''
      }
      if (
        values.checkboxList[values.checkboxList.length - 1] === false &&
        values.additionalInformation !== ''
      ) {
        values.additionalInformation = ''
      }
      if (values.isUrgent === 'No' && values.urgency !== '') {
        values.urgency = ''
      }
      if (
        values.clientApproveValue === 'Yes' &&
        values.externalApprover !== ''
      ) {
        values.externalApprover = ''
      }
      if (values.clientApproveValue === 'Yes' && values.approvelDate !== '') {
        values.approvelDate = ''
      }
      let data = ''
      if (values.typeReqValue === 'Small Works') {
        console.log('Work Scope Value', values.workScope)
        data = JSON.stringify({
          data: {
            typeReqValue: values.typeReqValue,
            psif: values.psif,
            projectName: values.projectName,
            workScope: values.workScope,
            numberInput: values.numberInput,
            reqCompletionDate: values.reqCompletionDate,
            isUrgent: values.isUrgent,
            urgency: values.urgency,
            isSensitiveInfo: values.isSensitiveInfo,
            clientApproveValue: values.clientApproveValue,
            externalApprover: values.externalApprover,
            approvelDate: values.approvelDate,
            checkboxList: values.checkboxList,
            additionalInformation: values.additionalInformation
          }
        })
      } else {
        data = JSON.stringify({
          data: {
            typeReqValue: values.typeReqValue,
            psif: values.psif,
            projectName: values.projectName,
            workScope: values.workScope,
            numberInput: values.numberInput,
            reqCompletionDate: values.reqCompletionDate,
            isUrgent: values.isUrgent,
            urgency: values.urgency,
            isSensitiveInfo: values.isSensitiveInfo,
            clientApproveValue: values.clientApproveValue,
            externalApprover: values.externalApprover,
            approvelDate: values.approvelDate,
            checkboxList: values.checkboxList,
            additionalInformation: values.additionalInformation
          }
        })
      }

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `https://io.dw.api.protovate.com/api/requests/${startRequestId}/sections/3/save`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        },
        data: data
      }

      await axios
        .request(config)
        .then((response: any) => {
          nextTab(4)
          console.log(response.data)
        })
        .catch((error: any) => {
          console.log(error)
        })

      return false
    } catch (error) {
      // Handle errors if needed
      console.error('Error:', error)
      return false
    }
  }

  const getWorkDetails = async () => {
    try {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://io.dw.api.protovate.com/api/requests/${startRequestId}/sections/3/view`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`
        }
      }

      await axios
        .request(config)
        .then((response: any) => {
          console.log('Coming Back Response', response.data.data.data)
          // setInitValue(response.data.data.data)
          const data = {
            typeReqValue: response.data.data.data.typeReqValue,
            clientApproveValue: response.data.data.data.clientApproveValue,
            psif: response.data.data.data.psif,
            projectName: response.data.data.data.projectName,
            urgency: response.data.data.data.urgency || '',
            externalApprover: response.data.data.data.externalApprover || '',
            numberInput: response.data.data.data.numberInput,
            reqCompletionDate: response.data.data.data.reqCompletionDate,
            approvelDate: response.data.data.data.approvelDate || '',
            isUrgent: response.data.data.data.isUrgent,
            isSensitiveInfo: response.data.data.data.isSensitiveInfo,
            workScope: response.data.data.data.workScope || '',
            additionalInformation:
              response.data.data.data.additionalInformation || '',
            checkboxList: response.data.data.data.checkboxList
          }
          setInitValue(data)
          // setFieldValue('psif', response.data.data.data.psif || '')
          // setFieldValue('projectName', response.data.data.data.projectName)
          // setFieldValue('urgency', response.data.data.data.urgency || '')
          // setFieldValue(
          //   'externalApprover',
          //   response.data.data.data.externalApprover || ''
          // )
          // setFieldValue(
          //   'clientApproveValue',
          //   response.data.data.data.clientApproveValue
          // )
          // setFieldValue('typeReqValue', response.data.data.data.typeReqValue)
          // setFieldValue('numberInput', response.data.data.data.numberInput)
          // setFieldValue(
          //   'reqCompletionDate',
          //   response.data.data.data.reqCompletionDate || ''
          // )
          // setFieldValue(
          //   'approvelDate',
          //   response.data.data.data.approvelDate || ''
          // )
          // setFieldValue('isUrgent', response.data.data.data.isUrgent)
          // setFieldValue(
          //   'isSensitiveInfo',
          //   response.data.data.data.isSensitiveInfo
          // )
          // setFieldValue(
          //   'checkboxList',
          //   response.data.data.data.checkboxList ||
          //     Array(checkboxOptions.length).fill(false)
          // )
          // setFieldValue(
          //   'workScope',
          //   response.data.data.data.workScope || JSON.parse('')
          // )
          // setFieldValue(
          //   'additionalInformation',
          //   response.data.data.data.additionalInformation || ''
          // )
        })
        .catch((error: any) => {
          console.log(error)
        })

      return false
    } catch (error) {
      // Handle errors if needed
      console.error('Error: here ', error)
      return false
    }
  }

  useEffect(() => {
    resetForm()
    getWorkDetails()
    if (values.additionalInformation) {
      setEditorState(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(values.additionalInformation))
        )
      )
    } else {
      setEditorState(EditorState.createEmpty())
    }

    // Check if values.workScope is not an empty string before converting
    if (values.workScope) {
      setEditorTwoState(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(values.workScope))
        )
      )
    } else {
      setEditorTwoState(EditorState.createEmpty())
    }
  }, [])

  const toolbarOptions = {
    options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'image'],
    inline: {
      options: ['bold', 'italic', 'underline', 'strikethrough']
    },
    blockType: {
      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote']
    },
    list: {
      options: ['unordered', 'ordered']
    },
    textAlign: {
      options: ['left', 'center', 'right', 'justify']
    }
  }

  const [_editorState, setEditorState] = useState(EditorState.createEmpty())

  const handleEditorChange = (newEditorState: any) => {
    // Update the editor state
    setEditorState(newEditorState)

    // Get the content as plain text or in any desired format
    const contentState = convertToRaw(newEditorState.getCurrentContent())
    const contentText = contentState.blocks
      .map((block) => block.text)
      .join('\n')

    setFieldValue('additionalInformation', contentText)
  }

  const [_editorTwoState, setEditorTwoState] = useState(
    EditorState.createEmpty()
  )

  const handleEditorTwoChange = (newEditorState: any) => {
    // Update the editor state
    setEditorTwoState(newEditorState)

    // Get the content as plain text or in any desired format
    const contentState = convertToRaw(newEditorState.getCurrentContent())
    const contentText = contentState.blocks
      .map((block) => block.text)
      .join('\n')
    setFieldValue('workScope', contentText)
  }
  return (
    <div className='digital-form-work-details'>
      <Form onSubmit={handleSubmit} className='form-class'>
        {/* Radio buttons */}
        <div className='radio-button'>
          <label>Type of Request</label>
          {typeReqRadioOptions.map((option, index: number) => (
            <div key={index}>
              <input
                type='radio'
                checked={values.typeReqValue === option}
                value={option}
                id='typeReqValue'
                name='typeReqValue'
                onChange={handleChange}
                onBlur={handleBlur}
                className='me-2'
              />
              <label>{option}</label>
            </div>
          ))}
        </div>
        <span className='form-error'>{errors.typeReqValue}</span>
        <div>
          <label>
            Client Reference Number (PSIF/PJ)
            <img src={infoIcon} alt='Info' />
          </label>
          <input
            type='text'
            placeholder='Type here'
            id='psif'
            name='psif'
            value={values.psif}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <span className='form-error'>{errors.psif}</span>
        <div>
          <label>Project Name</label>
          <input
            type='text'
            placeholder='Type here'
            id='projectName'
            name='projectName'
            value={values.projectName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <span className='form-error'>{errors.projectName}</span>
        <div className='text-editor-quill'>
          <div
            className={`additional-${
              values.typeReqValue === 'Small Works' ? 'active' : 'inactive'
            }-info`}
          >
            <label>Scope of Work - Small Works</label>
            <Editor
              toolbar={toolbarOptions}
              toolbarClassName='custom-toolbar-class'
              wrapperClassName='custom-wrapper-class'
              editorClassName='custom-editor-class'
              // onChange={(value: any) => getWorkScope(value)}
              onEditorStateChange={handleEditorTwoChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <span className='form-error'>{errors.workScope}</span>
        {/* Checkbox list */}
        <div className='checkbox-list'>
          <div
            className={`additional-${
              values.typeReqValue === 'Move Services' ? 'active' : 'inactive'
            }-info`}
          >
            <label>Scope of Work - Move Services</label>
            <div className='checklist-wrapper'>
              {checkboxOptions.map((option, index) => (
                <>
                  <FormGroup check key={index} className='custom-check'>
                    <Input
                      type='checkbox'
                      checked={values.checkboxList[index]}
                      id={`checkboxList_${index}`}
                      name='checkboxList'
                      onChange={(e) => {
                        const newCheckboxList = [...values.checkboxList]
                        newCheckboxList[index] = e.target.checked
                        setFieldTouched('checkboxList', true)
                        setFieldValue('checkboxList', newCheckboxList)
                      }}
                      onBlur={handleBlur}
                    />
                    <Label for={`checkboxList_${index}`}>{option}</Label>
                  </FormGroup>
                </>
              ))}
            </div>
          </div>
        </div>
        <span className='form-error'>
          {errors.checkboxList && typeof errors.checkboxList === 'object'
            ? 'An error occurred' // Replace this with the appropriate message
            : errors.checkboxList}
        </span>
        {/* additional value */}
        <div className='text-editor-quill'>
          <div
            className={`additional-${
              values.checkboxList[values.checkboxList.length - 1] === true &&
              values.typeReqValue === 'Move Services'
                ? 'active'
                : 'inactive'
            }-info`}
          >
            <label>Additional Information</label>
            <Editor
              toolbar={toolbarOptions}
              toolbarClassName='custom-toolbar-class'
              wrapperClassName='custom-wrapper-class'
              editorClassName='custom-editor-class'
              // onChange={(value: any) => getAdditionalInfo(value)}

              onEditorStateChange={handleEditorChange}
              onBlur={handleBlur}
            />
          </div>
        </div>
        <span className='form-error'>{errors.additionalInformation}</span>

        {/* Number inputs */}
        <div>
          <label>Maximum Funding Approval Value (CAD)</label>
          <input
            type='number'
            value={values.numberInput}
            id='numberInput'
            name='numberInput'
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <span className='form-error'>{errors.numberInput}</span>
        {/* Requested Completion Date */}
        <div>
          <label>Requested Completion Date</label>
          <input
            type='date'
            value={values.reqCompletionDate}
            id='reqCompletionDate'
            name='reqCompletionDate'
            onChange={handleChange}
            onBlur={handleBlur}
            min={new Date().toISOString().split('T')[0]}
          />
        </div>
        <span className='form-error'>{errors.reqCompletionDate}</span>
        {/* Radio buttons */}
        <div className='radio-button'>
          <label>Is it Urgent?</label>
          {radioOptions.map((option) => (
            <div key={option}>
              <input
                type='radio'
                checked={values.isUrgent === option}
                value={option}
                className='me-2'
                id='isUrgent'
                name='isUrgent'
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label>{option}</label>
            </div>
          ))}
        </div>
        <span className='form-error'>{errors.isUrgent}</span>

        <div
          className={`additional-${
            values.isUrgent === 'Yes' ? 'active' : 'inactive'
          }-info`}
        >
          <label className='switch'>Urgent - Comment Mandatory</label>
          <input
            type='text'
            placeholder='Explain the urgency here'
            value={values.urgency}
            id='urgency'
            name='urgency'
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <span className='form-error'>{errors.urgency}</span>

        {/* Radio buttons */}
        <div className='radio-button'>
          <label>Confidential / Sensitive Information Applicable?</label>
          {radioOptions.map((option) => (
            <div key={option}>
              <input
                type='radio'
                checked={values.isSensitiveInfo === option}
                value={option}
                className='me-2'
                id='isSensitiveInfo'
                name='isSensitiveInfo'
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label>{option}</label>
            </div>
          ))}
        </div>
        <span className='form-error'>{errors.isSensitiveInfo}</span>
        {/* Radio buttons */}
        <div className='radio-button'>
          <label>Are you the Final Client Approver?</label>
          {clientApproveRadioOptions.map((option) => (
            <div key={option}>
              <input
                type='radio'
                checked={values.clientApproveValue === option}
                value={option}
                className='me-2'
                id='clientApproveValue'
                name='clientApproveValue'
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label>{option}</label>
            </div>
          ))}
        </div>
        <span className='form-error'>{errors.clientApproveValue}</span>
        {/* Text inputs */}
        <div
          className={`additional-${
            values.clientApproveValue === 'No, an external approver is needed'
              ? 'active'
              : 'inactive'
          }-info`}
        >
          <label>Name of External Approver</label>
          <input
            type='text'
            value={values.externalApprover}
            id='externalApprover'
            name='externalApprover'
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <span className='form-error'>{errors.externalApprover}</span>
        {/*  Date of Approval */}
        <div
          className={`additional-${
            values.clientApproveValue === 'No, an external approver is needed'
              ? 'active'
              : 'inactive'
          }-info`}
        >
          <label>Date of Approval</label>
          <input
            type='date'
            value={values.approvelDate}
            id='approvelDate'
            name='approvelDate'
            onChange={handleChange}
            onBlur={handleBlur}
            max={new Date().toISOString().split('T')[0]}
          />
        </div>
        <span className='form-error'>{errors.approvelDate}</span>
        <p>Please attach any approval documents in the Attachments section.</p>
        {/* Submit button */}
        <div>
          <button type='submit'>Submit</button>
        </div>
      </Form>
    </div>
  )
}

export default MyForm
